import { getFeatureFlagsFromUrl } from '@mews/std';

const isObject = (value: unknown): value is object => typeof value === 'object';

export const parseFeatureFlagOption = (value?: string | null): Record<string, boolean> => {
    try {
        const decodedValue = value != null && value !== '' ? JSON.parse(window.atob(value)) : {};

        if (isObject(decodedValue)) {
            return Object.fromEntries(Object.entries(decodedValue).map(([key, val]) => [key, Boolean(val)]));
        }
        return {};
    } catch (e) {
        return getFeatureFlagsFromUrl();
    }
};
