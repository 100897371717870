import { CustomStyles } from './types';

export const addStyleProperties = (element: HTMLElement, styles: CustomStyles) => {
    Object.entries(styles).forEach(([styleProp, value]) => {
        if (typeof value === 'string') {
            // @ts-expect-error Element implicitly has an 'any' type because index expression is not of type 'number'. We cant use setProperty because it needs property names with kebabCase not camelCase
            element.style[styleProp] = value;
        }
    });
};
