import { KeyValue } from '@mews/enums';
import { DistributorAppInstance } from '..';
import { ApplicationInitialState } from '../types';
import { ApplicationOptions } from '../types/applicationOptions';
import { addStyleProperties } from './addStyleProperties';
import {
    DISABLED_SCROLL_STYLES,
    IFRAME_BODY_STYLES,
    IFRAME_HTML_STYLES,
    IFRAME_NAME,
    IFRAME_SRCDOC,
    IFRAME_STYLES,
    IFRAME_TRANSITION_LENGTH,
    IFRAME_ZINDEX,
    ONE_TICK,
} from './bootstrapConstants';
import { CustomStyles, LogFromLoader } from './types';

export function createAppContainer(
    contextWindow: Window,
    openElements: string | undefined,
    {
        isStandalone = false,
        logFromAppContainer,
    }: {
        isStandalone: boolean | undefined;
        logFromAppContainer: ({ message, type, data }: LogFromLoader) => void;
    }
) {
    const iframe = contextWindow.document.createElement('iframe');
    iframe.className = IFRAME_NAME;
    iframe.name = `${IFRAME_NAME}${Date.now()}`;
    addStyleProperties(iframe, IFRAME_STYLES);

    const iframeToggler = createIframeToggler(contextWindow, iframe);

    let loaded = false;
    let wasEarlyOpened = false;
    let isAppLoaded = false;
    let appInstance: DistributorAppInstance | undefined;

    iframe.onload = () => {
        loaded = true;
        if (iframe.contentDocument == null) {
            logFromAppContainer({
                message: 'Iframe.onLoad: contentDocument is nil',
                type: 'iframe-onLoad-contentDocument-is-nil',
                data: {
                    urlHref: contextWindow.location.href,
                    origin: contextWindow.origin,
                },
            });
        }

        /**
         * Some browsers execute onload after manipulation with contentDocument.
         * Without this check, those browsers would be stuck in infinite loop of
         * contentDocument manipulations (contentDocument.open, write, close) and onload executions.
         * We know this is a case for Internet Explorer 11.
         */

        if (iframe.contentDocument != null && iframe.contentDocument.doctype) {
            return;
        }

        // add doctype to the iframe
        const iframeContentDocument = iframe.contentDocument;
        iframeContentDocument?.open();
        // IFRAME_SRCDOC contains only constants
        // eslint-disable-next-line no-unsanitized/method
        iframeContentDocument?.write(IFRAME_SRCDOC);
        iframeContentDocument?.close();

        // css reset for iframes html and body tag
        if (iframe.contentWindow?.document.body != null) {
            addStyleProperties(iframe.contentWindow.document.body, IFRAME_BODY_STYLES);
        }
        if (iframe.contentDocument?.getElementsByTagName('html')[0] != null) {
            addStyleProperties(iframe.contentDocument.getElementsByTagName('html')[0], IFRAME_HTML_STYLES);
        }

        // bind closing of iframe to escape key
        if (!isStandalone) {
            iframe.contentWindow?.addEventListener('keydown', (e) => {
                if (e.key === KeyValue.Escape) {
                    iframeToggler.closeIframe();
                }
            });
        }

        const opener = getOpener(contextWindow, openElements, () => {
            open();
            if (appInstance) {
                appInstance.open();
            }
        });
        contextWindow.document.addEventListener('click', opener);
    };

    const api = {
        getElement,
        getDocument,
        onLoad,
        open,
        close: iframeToggler.closeIframe,
        loadApp,
        getIframeContentWindow,
        loadReactDevtools,
        setLanguageCode,
    };

    return api;

    function getIframeContentWindow() {
        return iframe.contentWindow;
    }

    function getElement() {
        return iframe;
    }

    function getDocument() {
        return iframe.contentDocument;
    }

    function setLanguageCode(languageCode: string) {
        return iframe.contentDocument?.getElementsByTagName('html')[0]?.setAttribute('lang', languageCode);
    }

    function onLoad(callback: (param: typeof api) => void) {
        if (loaded) {
            callback(api);
        } else {
            const { onload } = iframe;
            iframe.onload = () => {
                if (onload == null) {
                    logFromAppContainer({
                        message: 'Iframe.onLoad: onLoad is nil',
                        type: 'iframe-onLoad-is-nil',
                        data: {
                            urlHref: contextWindow.location.href,
                            origin: contextWindow.origin,
                        },
                    });
                }
                // @ts-expect-error expected 1 argument but got 0
                onload();

                callback(api);
            };
        }
    }

    function open() {
        if (!isAppLoaded) {
            wasEarlyOpened = true;
            if (iframe.contentDocument != null) iframe.contentDocument.body.style.cursor = 'wait';
        }

        iframeToggler.openIframe();
    }

    function loadReactDevtools() {
        if (iframe.contentWindow != null) {
            // eslint-disable-next-line no-underscore-dangle
            iframe.contentWindow.__REACT_DEVTOOLS_GLOBAL_HOOK__ = contextWindow.__REACT_DEVTOOLS_GLOBAL_HOOK__;
        }
    }

    function loadApp(
        src: string,
        appOptions: ApplicationOptions,
        initialState: ApplicationInitialState,
        callback: (arg: DistributorAppInstance) => void
    ) {
        // insert app script into iframe
        const script = contextWindow.document.createElement('script');
        script.crossOrigin = 'anonymous';
        script.src = src;
        script.onload = () => {
            script.onload = null;
            isAppLoaded = true;
            if (iframe.contentWindow == null || iframe.contentDocument == null) {
                return;
            }

            appInstance = iframe.contentWindow.Mews.runDistributorApp(appOptions, initialState);

            if (wasEarlyOpened) {
                iframe.contentDocument.body.style.cursor = 'auto';
                appInstance.open();
            }
            callback(appInstance);
        };
        iframe.contentDocument?.head.appendChild(script);
    }
}

function createIframeToggler(contextWindow: Window, iframe: HTMLIFrameElement) {
    const scrollToggler = createScrollToggler(contextWindow);
    let isOpened = false;

    return {
        openIframe() {
            if (!isOpened) {
                isOpened = true;
                addStyleProperties(iframe, {
                    opacity: '0.01',
                    zIndex: IFRAME_ZINDEX,
                });
                contextWindow.setTimeout(
                    () => addStyleProperties(iframe, { opacity: '1', transform: 'translateY(0)' }),
                    ONE_TICK
                );

                scrollToggler.disableScroll();
            }
        },

        closeIframe(this: void) {
            if (isOpened) {
                isOpened = false;
                addStyleProperties(iframe, { opacity: '0', transform: 'translateY(-20%)' });
                contextWindow.setTimeout(() => addStyleProperties(iframe, { zIndex: '-1' }), IFRAME_TRANSITION_LENGTH);
                scrollToggler.enableScroll();
            }
        },

        isOpened() {
            return isOpened;
        },
    };
}

function createScrollToggler(contextWindow: Window) {
    const getHtml = () => contextWindow.document.getElementsByTagName('html')[0];
    const getBody = () => contextWindow.document.body;

    const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;
    const changedStyleKeys = getKeys(DISABLED_SCROLL_STYLES);

    let scrollPosition: number;
    let originalHtmlStyle: CustomStyles;
    let originalBodyStyle: CustomStyles;

    let isDisabled = false;

    return {
        disableScroll() {
            if (!isDisabled) {
                const html = getHtml();
                const body = getBody();

                scrollPosition = contextWindow.scrollY;
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                if (html) {
                    originalHtmlStyle = pick(html.style, changedStyleKeys);
                    addStyleProperties(html, DISABLED_SCROLL_STYLES);
                }
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                if (body) {
                    originalBodyStyle = pick(body.style, changedStyleKeys);
                    addStyleProperties(body, DISABLED_SCROLL_STYLES);
                }

                isDisabled = true;
            }
        },

        enableScroll() {
            if (isDisabled) {
                const html = getHtml();
                const body = getBody();

                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                if (html) {
                    addStyleProperties(html, originalHtmlStyle);
                }
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                if (body) {
                    addStyleProperties(body, originalBodyStyle);
                }

                // Based on Sentry reports, scrollTo is not always available. Error: "TypeError: t.scrollTo is not a function. (In 't.scrollTo(0,a)', 't.scrollTo' is false)"
                if (typeof contextWindow.scrollTo === 'function') {
                    contextWindow.scrollTo(0, scrollPosition);
                }

                isDisabled = false;
            }
        },

        isDisabled() {
            return isDisabled;
        },
    };
}

const getOpener =
    (contextWindow: Window, querySelector: string | undefined, callback: () => void) => (event: Event) => {
        const els =
            querySelector != null
                ? Array.prototype.slice.call(contextWindow.document.querySelectorAll(querySelector))
                : [];
        let found = false;
        els.forEach((el) => {
            let node = event.target;
            while (node !== null) {
                if (node === el) {
                    found = true;
                    break;
                }
                node = (node as HTMLElement).parentNode;
            }
        });
        if (found) {
            event.preventDefault();
            callback();
        }
    };

function pick<T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
    return keys.reduce(
        (acc, key) => {
            if (typeof obj[key] !== 'undefined') {
                acc[key] = obj[key];
            }
            return acc;
        },
        {} as Pick<T, K>
    );
}
