export async function loadJson<T>(src: string): Promise<T> {
    return new Promise((resolve, reject) => {
        const request = new window.XMLHttpRequest();
        request.open('GET', src, true);

        request.onload = () => {
            if (request.status >= 200 && request.status < 400) {
                try {
                    resolve(JSON.parse(request.responseText));
                } catch (e) {
                    reject(e);
                }
            } else {
                reject();
            }
        };

        request.onerror = reject;

        request.send();
    });
}
