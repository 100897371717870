export const getFeatureFlagsFromUrl = (paramName = 'ffdebug') => {
    try {
        const searchParams = new URLSearchParams(window.location.search);
        const parsedOptions = JSON.parse(searchParams.get(paramName) || '{}');
        if (Object.keys(parsedOptions).length > 0) {
            window.console.info('Parsed feature flags from URL: ', parsedOptions);
        }

        return parsedOptions;
    } catch (error) {
        window.console.error('Failed to parse feature flags from URL', error);
    }

    return {};
};
