export const PLATFORM_PRIMARY_API_URL = 'https://api.mews.com';
export const CONFIGURATION_FILENAME = 'distributor/configuration';
export const LOCALIZATION_FILENAME = 'distributor/globalization';

export const ONE_TICK = 17;
export const IFRAME_ZINDEX = '100000';
export const IFRAME_TRANSITION_LENGTH = 450;
export const IFRAME_NAME = 'mews-distributor';
export const APP_CONTAINER_ID = 'distributor';
export const DEFAULT_TRACKING_CONSENT = true;

export const IFRAME_STYLES = {
    opacity: '0',
    border: 'none',
    width: '100%',
    height: '100%',
    position: 'fixed',
    transform: 'translate3d(0px, 0px, 0px)',
    background: 'white',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: '-1',
    transition: `
        transform ${IFRAME_TRANSITION_LENGTH}ms cubic-bezier(0.23, 1, 0.32, 1),
        opacity ${IFRAME_TRANSITION_LENGTH}ms cubic-bezier(0.23, 1, 0.32, 1)
    `,
} satisfies Partial<CSSStyleDeclaration>;

export const IFRAME_HTML_STYLES: Pick<CSSStyleDeclaration, 'height'> = {
    height: '100%',
};

export const IFRAME_BODY_STYLES: Pick<CSSStyleDeclaration, 'overflowX' | 'margin' | 'padding' | 'height'> = {
    margin: '0',
    padding: '0',
    overflowX: 'hidden',
    height: '100%',
};

export const DISABLED_SCROLL_STYLES: Pick<CSSStyleDeclaration, 'overflow' | 'margin' | 'padding'> = {
    overflow: 'hidden',
    margin: '0',
    padding: '0',
};

const SPINNER_STYLES = `
    .spinner {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(20px, 20px) rotate(-90deg);
    }
`;

const SPINNER_SRC = `
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="spinner">
        <circle cx="20" cy="20" r="15" fill="none" stroke-width="4" stroke="#ededed" stroke-linecap="round">
            <animate attributeName="stroke-dasharray" values="1,360; 90,360" dur="1.5s" repeatCount="indefinite" begin="-0.1s" keyTimes="0;1" keySplines="0.48 0 0.4 1" calcMode="spline" />
            <animate attributeName="stroke-dashoffset" values="0; -90" dur="1.5s" repeatCount="indefinite" begin="-0.1s" keyTimes="0;1" keySplines="0.48 0 0.4 1" calcMode="spline" />
        </circle>
        <circle cx="20" cy="20" r="15" fill="none" stroke-width="4" stroke="#ff5a65" stroke-linecap="round">
            <animate attributeName="stroke-dasharray" values="1,360; 90,360" dur="1.5s" repeatCount="indefinite" begin="-0.2s" keyTimes="0;1" keySplines="0.48 0 0.4 1" calcMode="spline" />
            <animate attributeName="stroke-dashoffset" values="0; -90" dur="1.5s" repeatCount="indefinite" begin="-0.2s" keyTimes="0;1" keySplines="0.48 0 0.4 1" calcMode="spline" />
        </circle>
        <circle cx="20" cy="20" r="15" fill="none" stroke-width="4" stroke="#3e3bd9" stroke-linecap="round">
            <animate attributeName="stroke-dasharray" values="1,360; 90,360" dur="1.5s" repeatCount="indefinite" begin="-0.3s" keyTimes="0;1" keySplines="0.48 0 0.4 1" calcMode="spline" />
            <animate attributeName="stroke-dashoffset" values="0; -90" dur="1.5s" repeatCount="indefinite" begin="-0.3s" keyTimes="0;1" keySplines="0.48 0 0.4 1" calcMode="spline" />
        </circle>
    </svg>
`;
const APP_CONTAINER_SRC = `<div id="${APP_CONTAINER_ID}" style="height: 100%">${SPINNER_SRC}</div>`;

export const IFRAME_SRCDOC = `<!DOCTYPE html><html><head><style>${SPINNER_STYLES}</style></head><body>${APP_CONTAINER_SRC}</body></html>`;
